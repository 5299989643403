"use client";

import { useHydrated } from "@utils/hooks";
import { analytics } from "./analytics";
import { useRef } from "react";

interface Props<T = Record<string, any>> {
  properties?: T;
}

export default function AnalyticsPage({ properties }: Props) {
  const hydrated = useHydrated();
  const pageviewTriggered = useRef(false);

  if (hydrated && !pageviewTriggered.current) {
    analytics.page(properties);
    pageviewTriggered.current = true;
  }

  return <></>;
}
